.map{
	min-width:100px;
	width: 80%;
	height: 500px;
	margin-top: 20px;
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 20px;
	border:0;
}


.trackChooseContainer{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.trackChooser{
	border-radius: 3px 3px 3px 3px;
	background-color: var(--contrast_color);	
	border-color: var(--contrast_color);
	margin-left: 5%;
	margin-right: 5%;
	padding: 5px;
	font-weight: bold;
	color: #EDEDED;
	margin-top: 5px;
	margin-bottom: 5px;
	min-height: 3em;
}


@media (min-width: 71em){
	.trackChooser{
		width:10%;
	}
}

@media (max-width: 71em){
	.trackChooser{	
		min-width:5em;
	}
}