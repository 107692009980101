.navContainer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
    min-height: 60px;
    margin-left: 100px;
}

.navItem{
    padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: bold;
}

.inactive {
	color: var(--text-color);
	display: inline-block;
	position: relative;
    transition: color 0.25s ease-out;
}

.inactive:hover{
    color: var(--contrast_color);
}

.ham-container .checkbox{
    height: 32px;
    width: 32px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 5;
    display: block;
    opacity: 0;
}

.ham-container .hamburger-lines{
    height: 26px;
    width: 32px;
    position: absolute;
    top: 22px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ham-container .hamburger-lines .line{
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--text-color);
}

.ham-container .hamburger-lines .line1{
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.ham-container .hamburger-lines .line2{
    transition: transform 0.2s ease-in-out;
}

.ham-container .hamburger-lines .line3{
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.ham-container input[type="checkbox"]:checked ~ .hamburger-lines .line1{
    transform: rotate(45deg);
}

.ham-container input[type="checkbox"]:checked ~ .hamburger-lines .line2{
    transform:scaleY(0);
}

.ham-container input[type="checkbox"]:checked ~ .hamburger-lines .line3{
    transform: rotate(-45deg);
}

.ham-container input[type="checkbox"]:checked ~ .navContainer .navItem{
    transform: translate(0%);
}


.logoBox{
	height: fit-content;
	z-index: 1;
}

.logo{
	transform: scale();
	max-height: 60px;
}

.spacer{
    min-height: 64px;
}

.ref {
	text-decoration: none;
	font-weight: bold;
}

@media (min-width: 42em){
	.ham-container .hamburger-lines{
		display: none;
	}

    .ham-container .logoBox{
        position: absolute;
        left: 10px;
    }

    .ham-container .spacer{
        display: none;
    }
}

@media (max-width: 42em){
	.ham-container .hamburger-lines{
		display: flex;
	}

    .ham-container .navContainer{
        display: none;
        flex-direction: column;
        justify-content: space-between;
    }

    .ham-container .logoBox{
        position:absolute;
        left: 50%;
        display: flex;
        align-items: center;
        margin-left: -35px;
        width: 70px;
        justify-content: center;
    }
    
    .ham-container .spacer{
        display: block;
    }

    .ham-container input[type="checkbox"]:checked ~ .navContainer{
        display: flex;
        margin-left: 0px;
    }
}