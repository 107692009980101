@font-face { font-family: Delicious; src:url('https://fonts.googleapis.com/css?family=Inter:400,500,600,700&display=swap')}
@font-face { font-family: Delicious; src:url('https://fonts.googleapis.com/css?family=Poppins:600&display=swap')}
@font-face { font-family: Delicious; src:url('https://fonts.googleapis.com/css?family=Roboto:400&display=swap')}

:root{
	--contrast_color:#f4a031;
	--bg_color: #EDEDED;
	--panel-color: #FFFFFF;
	--text-color:#262626;
	--shadow-color: #f2f2f2;
	--box-shadow: #3333331a;

}

@media (prefers-color-scheme: dark){
	:root{
		--contrast_color:#f48931d2;
		--bg_color: #262626;
		--panel-color: #202020;
		--text-color:#EDEDED;
		--shadow-color: #2c2c2c;
		--box-shadow: #00000000;
		--link-color: #6098e0;
	}

	a {
		color: var(--link-color);
	}
}

body {
  margin: 0;
  font-family:'Delicious', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--bg_color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.underline{
  text-decoration: underline;
}

.autoWidth{
	width: auto;
}

.min-width16{
	min-width: 16px;
}

.parallel{
  display: flex;
  width: 100%;
  justify-content: center;
}

h1{
	margin-left: 10%;
	margin-right: 10%;
	padding-top: 25px;
}

.content{
	margin-left: 10%;
	margin-right: 10%;
}

.contentCenter{
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
	justify-content: center;
}
select{
	color: var(--text-color);
}

.contentRight{
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
	justify-content: right;
}

.centered{
	text-align: center;
}

.inline{
	display: flex;
	flex-direction: row;
}

.main{
	width: 100%;
	justify-content: top; 
	align-items: center;
}

.contentContainer{
	background-color: var(--panel-color);
	min-width: 90%;
	margin-left: 5%;
	margin-right: 5%;
	padding-bottom: 5%;
	border-radius: 20px 20px 20px 20px;
	align-items: center;
	box-shadow: var(--box-shadow) 0px 4px 32px;
}

.downloadBtn{
	display: flex;
	align-items: center;
	border-radius: 3px 3px 3px 3px;
	background-color: var(--contrast_color);	
	border-color: var(--contrast_color);
	padding: 5px;
	font-weight: bold;
	color: #EDEDED;
}

.silentLink{
	text-decoration: none;
}

select{
	font-weight: bold;
	min-height: 3em;
	max-height: 3em;
	padding-left: 1em;
	padding-right: 1em;
	background-color: var(--bg_color);
	border-radius: 1.5em;
	margin-right: 1em;
	margin-bottom: 1em;
}

table tr:first-of-type td:first-child{
	border-radius: 0.5em 0 0 0;
}

table tr:first-of-type td:last-child{
	border-radius: 0 0.5em 0 0;
}

table tr:first-of-type th{
	border-radius: 0.5em 0.5em 0 0;
}

table tr:last-of-type td:first-child{
	border-radius: 0 0 0 0.5em;
}

table tr:last-of-type td:last-child{
	border-radius: 0 0 0.5em 0;
}

.permaWarning{
	color:red;
}

@media (min-width: 31em){
	.warning{
		visibility: hidden;
		height: 0px;
	}
}

@media (max-width: 31em){
	.warning{
		visibility: visible;
		color:red;
	}
}

.altiFrame{
	position: relative;
	top: -350px;
	width: inherit;
	height: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

table{
	width: fit-content;
}

table td, tbody th{
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 1em;
	padding-right: 1em;
}

table td {
	padding-left: 1em;
	padding-right: 1em;
}

table tr:nth-child(odd) {
	background-color: var(--bg_color);
}

table tr:nth-child(even){
	background-color: var(--shadow-color);
}

.emphasized{
	color: var(--contrast_color);
}

.contentShadow{
	min-width: 86%;
	margin-left: 7%;
	margin-right: 7%;
	margin-bottom: 50px;
	border-radius: 0px 0px 20px 20px;
	background-color: var(--shadow-color);
	height: 15px;
	box-shadow: var(--box-shadow) 0px 4px 24px;
	
}