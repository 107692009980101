.foot{
    min-width: 100%;
    min-height: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    background-color: var(--footerCol);
}

.contentinFoot{
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

.small{
    font-size: small;
}